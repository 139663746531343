import { ResponsiveObject, StyleProps } from "@biblioteksentralen/react";

// Usually hover effects shouldn't be used on mobile and tablet
/**
 * @deprecated
 * This function doesnt actually check for mobile devices, only screen width. We should not remove hover-effects for small laptops and browserwindows
 */
const onDesktop = (props: StyleProps): ResponsiveObject<StyleProps> => ({ base: {}, md: props });

export default onDesktop;

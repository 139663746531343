import { colors } from "@biblioteksentralen/react";
import { seededRandom } from "@biblioteksentralen/utils";
import convert from "color-convert";

export function isBright(color: string) {
  if (!color.match(/^#[0-9,a,b,c,d,e,f]{6}$/i)) {
    console.error(`I only understand hex-colors, got: "${color}"`);
  }

  const lightness = convert.hex.hsl(color)[2];
  return lightness > 50;
}

export const accentColors = [
  colors.accentBlueDark,
  colors.accentGreen,
  colors.accentPeach,
  colors.accentPurple,
  colors.lightGreen,
  colors.lightPeach,
  colors.accentBlueMedium,
];

export const getAccentColorFromSeed = (seed: string) => {
  const index = Math.floor(seededRandom(seed) * accentColors.length);
  return accentColors[index];
};

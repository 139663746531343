import { Box, colors } from "@biblioteksentralen/react";
import { ForwardedRef, ReactNode, forwardRef } from "react";
import { useSitePalette } from "../../utils/useSitePalette";

const PageHeaderComponent = ({ children }: { children: ReactNode }, ref: ForwardedRef<HTMLDivElement>) => {
  const paletteColor = useSitePalette().colors.header;

  return (
    <Box
      ref={ref}
      as="header"
      borderBottom={paletteColor.isBright ? `${colors.grey15} solid 0.15rem` : undefined}
      {...paletteColor.css}
    >
      {children}
    </Box>
  );
};

export const PageHeader = forwardRef(PageHeaderComponent);

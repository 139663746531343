"use client";

import { Grid } from "@biblioteksentralen/react";
import { Fillern } from "../../components/errorPages/Fillern";
import { UnknownDomainHeader } from "../../components/errorPages/UnknownDomainHeader";
import { SplashPageFooter } from "../../components/splashPage/SplashPageFooter";
import { useReportError } from "../global-error";

type Props = {
  error: Error;
};

const PlatformErrorPage = ({ error }: Props) => {
  useReportError(error);

  return (
    <Grid gridTemplateRows="auto 1fr auto" minHeight="100vh">
      <UnknownDomainHeader />
      <Fillern />
      <SplashPageFooter />
    </Grid>
  );
};

export default PlatformErrorPage;

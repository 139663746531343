import { Link, Text } from "@biblioteksentralen/react";
import { splashSiteUrl } from "@libry-content/common";
import onDesktop from "../../utils/onDesktop";
import { HeaderFooterContainer } from "../ContentContainer";
import { PageHeader } from "../layout/PageHeader";

export const UnknownDomainHeader = () => (
  <PageHeader>
    <HeaderFooterContainer padding="1rem 1.75rem" fontSize="0.9rem" gridGap="2rem" textDecoration="none">
      <Link href={splashSiteUrl.toString()} textDecoration="none" _hover={onDesktop({ textDecoration: "underline" })}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Text as="span" fontWeight="bold">
          LIBRY
        </Text>{" "}
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Text as="span">CONTENT</Text>
      </Link>
    </HeaderFooterContainer>
  </PageHeader>
);
